<template>
  <div class="events">
    <h1>Events for Good</h1>
    <EventCard v-for="event in events" :key="event.id" :event="event" />
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from "@/components/EventCard.vue";
import EventService from '@/services/EventService';

export default {
  name: "EventList",
  components: {
    EventCard,
  },
  data() {
    return {
      events:  null
    }
  },
  created() {
      EventService.getEvents('https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3/events')
      .then(response => {
        this.events = response.data
      })
      .catch(error => {
        console.log(error)
      })
  }
};
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>